// Styles file for `autogo2.insurancespecialists.com`.

// Styles written here will override the corresponding component
// This is a stylesheet example of `Harpquiz`.

import { css, keyframes } from 'styled-components';
import images from './images';

// Theming
const DEFAULT_COLORS = {
  questionTitle: '#333',
  text: '#fff',
  headline: '#fff',
  link: '#1f5db9',
  choiceInput: '#1f5cb9',
  choiceInputHover: '#1b406a',
  submitButtonBackground: '#1f5db9',
  submitButtonText: '#fff',
  headerBackground: '#f5f5f5',
  mainBackground: '',
  surveyBackground: '',
  footerBackground: '#2e3435',
  progressBarBackground: '#f5f5f5',
  progressBarFill: '#46a762',
  sliderFill: '#f5f5f5',
};

const DEFAULT_FONT = {
  questionTitle: '44px',
  text: '16px',
  headline: '28px',
  submitButton: '30px',
  tcpa: '11px',
  footerText: '12px',
};

const DEFAULT_SIZE = {
  headerHeight: 'auto',
  headerMobileHeight: '',
  submitButtonWidth: '400px',
  inputFieldHeight: '44px',
  inputFieldWidth: '650px',
  inputFieldMobileWidth: '',
};

// Header
const HeaderContainer = css`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  /* height: ${DEFAULT_SIZE.headerHeight}; Don't forget to change the min-height calculation for SurveyBackground */
  width: 100vw;
  margin: auto;
  padding: 7px 20px 0;
  background: ${DEFAULT_COLORS.headerBackground};
  &.vwoTest{
    display: none;
    .main-app-page-0 & {
      display: flex;
    }
  }
  @media (max-width: 801px) {
    justify-content: center;
  }
`;

const HeaderImage = css`
  width: calc(100% - 20px);
  max-width: 225px;
  max-height: 100%;
  margin: 0 0 10px 0;
  @media (max-width: 801px) {
    max-width: 200px;
    margin: 0 0 2px 0;
  }
  .bpAutoSavings &,
  .bpAutoSaver & {
    max-width: 300px;
    margin: 10px 0 10px 10px;
  }
  .margin6 & {
    margin-bottom: 6px;
  }
  .margin0 & {
    margin-bottom: 0;
  }
  .mWidth125 & {
    max-width: 125px;
  }
  .mWidth188 & {
    max-width: 188px;
  }
  .mWidth225 & {
    max-width: 225px;
  }
  .mWidth265 & {
    max-width: 265px;
  }
`;

// Only used when sites background color is needed
// specificy "background-color" because of the blurred-rates image.
const AppWrapper = css`
  /* background-color: ${DEFAULT_COLORS.mainBackground} !important; */
  &.main-app-page-0 {
    #insLogos {
      display: flex;
    }
    #progressContainer,
    #progress-bar,
    #progressPercentage{
      display: none;
    }
    #survey-background {
      margin-bottom: 60vh;
      &.dynamicSite{
        padding-top: 40px;
      }
    }
  }
  #insLogos {
    display: none;
  }
`;

// Main Background
const SurveyBackground = css`
  min-height: calc(100vh - 20px);
  width: 100vw;
  margin: auto;

  background-color: ${DEFAULT_COLORS.surveyBackground};

  .main-app-page-0 & {
    background: #224d98;
    min-height: auto;
    padding-bottom: 40px;
  }

  &.hero {
    background: #0a79cd;
  }
  @media (max-width: 801px) {
    min-height: calc(100vh - 120px);
  }
`;

const SurveyContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background-color: #fff; */
  margin: auto;
  /* padding-top: 20px; */
  @media (max-width: 801px) {
    padding: 0 10px;
  }

  div {
    &#alternate-container {
      /* Used if you need to overwrite the width of the hidden container */
      /* width: 100%; */
    }
  }

  .hero & {
    margin-top: 0;
  }
`;

const QuestionNumber = css`
  font-size: 14px;
  width: 100%;
  max-width: ${DEFAULT_SIZE.inputFieldWidth};
  margin-bottom: 5px;
`;

const FormContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 650px;
  padding: 10px 0;

  &.initial {
    background: #d7e4f5;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
    width: 100%;
    max-width: 450px;
    padding: 20px;
    z-index: 10;
    @media (max-width: 801px) {
      width: calc(100% + 12px);
      margin-left: -6px;
    }

    h1 {
      font-size: 16px;
      text-align: start;
      margin-bottom: 0;
    }
  }

  .hero & {
    background: #fff;
    padding: 10px 30px;
    max-width: 600px;
  }
`;

const GlobalSealImg = css`
  margin: 20px;
  max-width: 110px;
`;

// Containers
// Multiple choice
const ChoiceContainer = css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 10px 40px;
  margin: 0 0 10px;
  transition: background-color 230ms ease;
  width: 100%;
  max-width: ${DEFAULT_SIZE.inputFieldWidth};
  /* height: ${DEFAULT_SIZE.inputFieldHeight}; */
  background-color: ${DEFAULT_COLORS.choiceInput};
  color: ${DEFAULT_COLORS.text};
  cursor: pointer;
  z-index: 1;
  border-radius: 4px;
  min-height: 58px;
  font-weight: 500;

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${DEFAULT_COLORS.choiceInputHover};
    transform: scaleX(0);
    transform-origin: 0 50%;
    transition-property: transform;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
    border-radius: 4px;
  }

  .hero & {
    background: #fff;
    color: #000;
    border: 2px solid #bbb;
  }

  &:hover {
    color: #fff;
    &:before {
      transform: scaleX(1);
    }
  }

  @media (max-width: 600px) {
    &:hover {
      color: ${DEFAULT_COLORS.text};
      &:before {
        all: unset;
      }
    }
  }
`;

// Styles for selected radio button (multiple-choice)
const InputContainer = css`
  width: 100%;
  max-width: ${DEFAULT_SIZE.inputFieldWidth};

  &.input-VehicleYear,
  &.input-VehicleYear2,
  &.input-BirthDay {
    flex-basis: 23%;
    margin: 0 1% 10px;

    > label {
      margin: 0;
      padding: 5px;
      justify-content: center;
    }
  }

  &.input-VehicleMake,
  &.input-VehicleMake2,
  &.input-InsuranceCarrier,
  &.input-CurrentlyInsured,
  &.input-NumberOfVehicles,
  &.input-Education,
  &.input-Occupation,
  &.input-Violation,
  &.input-Accident,
  &.input-DoesRequireSR22,
  &.input-own_rent,
  &.input-DesireHomeownersInsurance,
  &.input-PropertyType,
  &.input-LowerCarPayment {
    flex-basis: 48%;
    margin: 0 1% 10px;

    > label {
      margin: 0;
      padding: 5px;
      justify-content: center;
      text-align: center;
    }
  }

  &.input-VehicleModel,
  &.input-VehicleModel2,
  &.input-BirthMonth {
    flex-basis: 31%;
    margin: 0 1% 10px;
    text-align: center;
    > label {
      margin: 0;
      padding: 5px;
      justify-content: center;
    }
  }
  @media (max-width: 801px) {
    &.input-VehicleModel,
    &.input-VehicleModel2 {
      > label {
        height: 72px;
        line-height: 1.1;
      }
    }
  }

  > input:checked + label {
    background: url(${images.Checkmark}) 94% 50%/20px no-repeat
      ${DEFAULT_COLORS.choiceInputHover};

    .hero & {
      color: #fff;
    }
  }
`;

// Input
const inputStyle = css`
  width: 100%;
  max-width: 430px;
  height: ${DEFAULT_SIZE.inputFieldHeight};
  border-radius: 3px;
  font-size: 16px;
  background: #fff;
  border: ${props =>
    props.border
      ? '1px solid red'
      : '2px solid #bbb'} !important; /* overrides initial setting*/

  ::-webkit-input-placeholder {
    text-align: center;
  }
  text-align: center;
`;

const InputField = css`
  ${inputStyle}
  border: ${props => props.border};

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const StyledTextArea = css`
  ${inputStyle}
  border-radius: 5px;
  border: ${props => props.border};
`;

const AddressContainer = css`
  display: flex;
  flex-direction: column;
  /* max-width: ${DEFAULT_SIZE.inputFieldWidth}; */
  width: 100%;

  p {
    margin: 10px auto 0;
    width: 100%;
    max-width: 450px;
  }

  input {
    width: 100%;
    max-width: ${DEFAULT_SIZE.inputFieldWidth};
    margin: 5px auto;
  }
`;

// Dropdown
const StyledSelect = css`
  ${inputStyle}
  margin: 5px auto;
  padding-left: 10px;
  border: 1px inset #fff;
  -webkit-appearance: none;
  background: url(${images.Dropdown}) 98% 50% / 15px no-repeat #fff;
`;

// Zip input
const StyledZip = css`
  ${inputStyle}
  margin-bottom: 12px;
  border: ${props =>
    props.border
      ? '1px solid red'
      : '2px solid #bbb'} !important; /* overrides initial setting*/
  padding: 0 20px;
  text-align: center;

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &&& {
    width: 100%;
  }
`;

const FinalInputContainer = css`
  display: flex;
  flex-direction: column;
  width: 100%;

  input,
  select {
    margin-top: 10px;
    margin-bottom: 5px;
    width: 100%;
    max-width: 100%;
    height: 40px;
  }
`;

// Hidden inputs
const HiddenContainer = css`
  display: flex;
  flex-direction: column;
  margin: 10px auto 0;
  width: 100%;

  p:not(.error-text) {
    color: #1b406a;
  }

  input {
    height: ${DEFAULT_SIZE.inputFieldHeight};
  }
`;

// Survey styles
const QuestionTitle = css`
  color: ${DEFAULT_COLORS.questionTitle};
  font-weight: 600;
  font-size: ${DEFAULT_FONT.questionTitle};
  line-height: 1;
  margin-bottom: 15px;
  margin-top: 30px;
  text-align: center;
  &#questionTitle-InsuranceCarrier,
  &#questionTitle-BirthMonth,
  &#questionTitle-BirthDay,
  &#questionTitle-BirthYear,
  .hero & {
    color: #1b406a;
    font-size: 20px;
    text-align: left;
  }

  @media (max-width: 801px) {
    font-size: 27px;
  }
`;

const InputTitle = css`
  margin-bottom: 0;
  color: #1b406a;
`;

const Subtitle = css`
  display: none;

  &.subtitle-AnnualMileage,
  &.subtitle-email {
    text-align: center;
    margin-bottom: 20px;
    display: block;
    color: #676767;
    margin-top: -10px;
  }
`;

const TipContainer = css`
  text-align: center;
  background: #fff;
  padding: 10px;
  color: #000;
  font-size: 15px;
  border-radius: 3px;
  width: 80%;
`;

const QuestionContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &.question-container-first_name {
    h4 {
      color: #1b406a;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  &.question-container-zipcode {
    h4 {
      margin-top: 0;
      text-align: left;
    }
    .question-title-inputs {
      display: none;
    }
  }
`;

const QuestionBodyContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  .question-container-first_name & {
    &.questionbody-0 {
      margin-top: 30px;
    }
  }

  &.question-CurrentlyInsured,
  &.question-NumberOfVehicles,
  &.question-Education,
  &.question-Occupation,
  &.question-Violation,
  &.question-Accident,
  &.question-DoesRequireSR22,
  &.question-own_rent,
  &.question-DesireHomeownersInsurance,
  &.question-PropertyType,
  &.question-LowerCarPayment {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

// Slider
const StyledSlider = css`
  width: 100%;
`;

const StyledThumb = css`
  background: url(${images.SliderArrows}) 50% 50%/14px no-repeat #333;
  border: 1px solid #bdc3c7;
  width: 29px;
  height: 29px;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  z-index: 1;
  transform: scale(1.1);
`;

const DisplayNumber = css`
  font-size: ${({ primary }) => (primary ? '18px' : '14px')};
  font-weight: 500;
  padding: 10px;
  margin-bottom: 0;
`;

const NumberContainer = css`
  display: flex;
  justify-content: space-between;
  width: inherit;
`;

const SliderContainer = css`
  width: inherit;
  height: 10px; /* Don't forget to change the height of "FillContainer" as well */
  border-radius: 8px;
  border: 1px solid #bdc3c7;
  background: #fff;
`;

const FillContainer = css`
  position: relative;
  height: 10px;
  border-radius: 8px 0 0 8px;
  background: ${DEFAULT_COLORS.sliderFill};
  width: ${props => `${props.width}%`};
`;

// ProgressBar
const ProgressBarContainer = css`
  text-align: center;
  /* margin: 5px 0 0 0; */
  background: #224d98;
  padding: 20px 0px 15px;
`;
const ProgressContainer = css`
  margin: 0 auto 10px auto;
  width: 50vw;
  max-width: 540px;
  height: 14px;
  background: ${DEFAULT_COLORS.progressBarBackground};
  border-radius: 10px;
  overflow: hidden;
  z-index: 9;
  position: relative;
  margin-top: 10px;

  .hero & {
    margin: 0;
    width: 100vw;
    max-width: 100%;
    height: 50px;
    background: #3071bb;
    border-radius: 0;
    overflow: hidden;
  }

  @media (max-width: 801px) {
    width: 100%;
    max-width: 345px;
    height: 10px;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    max-width: 400px;
  }
`;

const StatusBar = css`
  background-size: 50px 50px;
  width: ${props => props.width};
  height: inherit;
  background-color: ${DEFAULT_COLORS.progressBarFill};
  transition: width 0.25s ease;
  border-radius: 10px;
  border: 1px solid #fff;

  .hero & {
    background-color: #1b406a;
    border-radius: 0;
    border: none;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: ${props => props.width};
      transform: translateX(-1px);
      width: 30px;
      height: inherit;
      clip-path: polygon(0 0, 0% 100%, 100% 100%);
      background-color: inherit;
      transition: all 0.25s ease;
    }
  }
`;

const NumberStatus = css`
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  @media (max-width: 801px) {
    font-size: 18px;
    font-weight: 500;
    margin-top: 15px;
  }
`;

// Used for the footer progressbar.
const FooterNumberStatus = css`
  position: absolute;
  left: 50%;
  top: 15px;
  margin-left: -52px;
  color: #fff;
  font-weight: 900;

  @media (max-width: 800px) {
    display: none;
  }
`;

// buttons
const SubmitButton = css`
  margin: 25px 0 18px;
  width: 100%;
  height: 54px;
  border-radius: 2px;
  background-color: ${DEFAULT_COLORS.submitButtonBackground};
  border: 0px;
  font-size: ${DEFAULT_FONT.submitButton};
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${DEFAULT_COLORS.submitButtonText};
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  &#next-button {
    &:after {
      content: '';
      height: 20px;
      width: 20px;
      position: relative;
      background: url(${images.NextArrowWhite}) 0 50%/7px no-repeat;
      top: 1px;
      bottom: 0;
      transform: rotate(180deg);
    }
  }
  /* &:hover {
    opacity: 0.9;
  } */
  /* first frame button color is different */
  .initial & {
    background-color: #ed711a;
  }

  /* Final Submit button is different than the 'next' buttons */
  &#submit-button {
    max-width: 450px;
    width: 100%;
    font-size: 29px;
    font-weight: 500;
    @media (max-width: 801px) {
      font-size: 26px; /* needs to be 26px for iphone x */
    }
    &:after {
      content: '';
      height: 20px;
      width: 20px;
      position: relative;
      background: url(${images.NextArrowWhite}) 0 50%/7px no-repeat;
      top: 1px;
      bottom: 0;
      transform: rotate(180deg);
    }
  }
`;

const BackButton = css`
  border: none;
  background: inherit;
  color: ${DEFAULT_COLORS.link};
  margin-bottom: 5px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 800;
  position: relative;

  &#previous-button {
    &:before {
      content: '';
      height: 20px;
      width: 20px;
      position: absolute;
      background: url(${images.NextArrow}) 10% 50%/6px no-repeat;
      top: 1px;
      left: -5px;
      bottom: 0;
      /* transform: rotate(180deg); */
    }
  }

  &:hover {
    opacity: 0.8;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    background: none;
  }
`;

const ButtonContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: ${DEFAULT_SIZE.submitButtonWidth};

  &#final-button-container {
    max-width: 400px;
    #previous-button {
      display: none;
    }
  }
`;
const BackButtonSpacer = css`
  height: 10px;
`;

// Footer
const FooterContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 30px;
  background: ${DEFAULT_COLORS.footerBackground};
  color: #8b939d;
  max-width: 960px;
  width: 100%;
  margin: 0 auto 0;

  p {
    margin-bottom: 10px;
  }

  a {
    color: ${DEFAULT_COLORS.link};
  }
  @media (max-width: 801px) {
    padding: 20px 0;
  }
`;

const DisclosureContainer = css`
  width: 100%;
  text-align: center;
  max-width: 960px;
  margin: 10px auto;
  a {
    display: inline-block;
    margin-bottom: 20px;
    font-size: 12px;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    max-width: 960px;
    width: 95%;
    text-align: center;
  }
`;

const ModalButtonContainer = css`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  margin: 2vh auto;
  border-top: 1px solid #c9c9c9;
  padding-top: 1vh;

  span {
    color: #707985;
  }

  @media (max-width: 500px) {
    width: 98%;
  }
`;

const ModalButton = css`
  border: 0;
  background: inherit;
  color: #707985;
  font-size: 13px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

// Modal
const ModalBackground = css`
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 30;
`;
const ModalContainer = css`
  margin: auto;
  background: white;
  width: ${props => (props.primary ? '50vw' : '70vw')};
  height: ${props => (props.primary ? '60vh' : '80vh')};
  padding: 3vh 3vw;
  border-radius: 10px;
  overflow: auto;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const ExitButton = css`
  top: ${props => (props.primary ? '19vh' : '9vh')};
  right: ${props => (props.primary ? '24vw' : '14vw')};
  position: absolute;
  border-radius: 50%;
  border: 1px solid #eee;
  font-size: 1.5em;
  cursor: pointer;
  transition: all 0.5s ease;
  height: 33px;
  width: 33px;
  background: #fff;

  &:hover {
    background: #969696;
    color: #fff;
  }

  @media (max-width: 900px) {
    right: 3vw;
  }
`;

const ModalInformation = css`
  display: flex;
  flex-direction: column;
  /* overflow: scroll; */
  line-height: 1.5em;

  br {
    line-height: 2em;
  }

  @media (max-width: 600px) {
    font-size: 0.8em;
  }
`;

const BoldText = css`
  font-size: 12px;
  color: ${props => props.primary && '#C1794C'};
  text-align: ${props => props.primary && 'center'};
  text-transform: uppercase;
`;

const Text = css`
  color: #989898;
  font-size: 14px;
  font-weight: ${props => props.primary && '700'};
  margin-bottom: ${props => props.primary && '0'};
`;

const FooterText = css`
  margin-top: ${props => props.primary && '30px'};
  white-space: pre-line;
  text-align: center;
  font-size: ${DEFAULT_FONT.footerText};
  color: ${({ primary }) => primary && '#767676'};
`;

const ImageList = css`
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const HeadlineContainer = css`
  text-align: center;
  margin: 0 -20px;
  background: #224d98;
  padding: 40px 0px 55px;
  width: 100vw;
  .bpAuto & {
    padding: 38px 0px;
  }
  &.keepHeadLine {
    padding: 10px 0px 0;
    margin: 5px -20px 0;
  }
  .main-app-page-0 & {
    &.keepHeadLine {
      padding: 38px 0px;
    }
  }
`;

const HeadlineHeader = css`
  color: ${DEFAULT_COLORS.headline};
  font-size: ${DEFAULT_FONT.headline};
  font-weight: 500;
  margin-bottom: -10px;
  /* display: none; */
  .autosavingsdaily & {
    font-size: 24px;
  }
  @media (max-width: 800px) {
    font-size: 20px;
    font-weight: 500;
    .autosavingsdaily & {
      font-size: 16px;
    }
  }
`;

const SubHeader = css`
  font-size: ${DEFAULT_FONT.text};
  font-weight: 200;
  color: ${DEFAULT_COLORS.headline};
  margin-bottom: 0;
`;

const SmallText = css`
  font-size: ${props => (props.secondary ? '0.9em' : '0.7em')};
  font-weight: ${props => (props.primary ? '700' : '200')};
  max-width: 500px;
  margin: auto;
`;

const ErrorText = css`
  color: #ee0000;
  font-style: italic;
  text-align: center;
  font-size: 14px;
  font-weight: 550;
  margin-top: 15px;
`;

const FinalDisclosureContainer = css`
  padding: 20px;
  background-color: #fbfbfb;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  min-height: 20px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  max-width: 800px;
  margin: 0 auto 0 auto;

  p,
  label {
    margin-bottom: 2px;
    font-size: ${DEFAULT_FONT.tcpa};
    color: #767676;
    line-height: 1.7;
  }
`;

// Loading Spinner
const Bounce = keyframes`
  0%, 80%, 100% { 
    transform: scale(0);
  } 40% { 
    transform: scale(1.0);
  }
`;

const SpinnerContainer = css`
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
`;

const Spinner = css`
  width: 18px;
  height: 18px;
  background-color: #5598c1;
  border-radius: 100%;
  display: inline-block;
  animation: ${Bounce} 1.4s infinite ease-in-out both;
  animation-delay: ${props => props.delay};
`;

const LoadingContainer = css`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
`;

const SpinnerMainContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  width: auto;
  padding: 40px;
  background: #fff;
`;

const CongratsHolder = css`
  /* Style property not used in this survey */
`;

const CheckboxWrapper = css`
  display: flex;
  flex-direction: column;
  margin-top: 1vh;
  width: 90%;

  label {
    width: 100%;
    justify-content: start;
    height: 30px;
    color: #888;
  }
`;

const CheckboxContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px 0;
`;

const CheckboxLabel = css`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  > input {
    -webkit-appearance: none;
    height: 0;
    width: 0;
    margin-left: 5px;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      -webkit-appearance: inline-block;
      height: 20px;
      width: 20px;
    }
  }

  > input[type='checkbox']:before {
    content: '';
    display: block;
    position: absolute;
    width: 22px;
    height: 22px;
    top: 0;
    left: 0;
    border: 1px solid #dedede;
    border-radius: 3px;
    background-color: white;
    box-shadow: inset #777 0 0 2px;
    margin: 4px;
  }
  > input[type='checkbox']:checked:after {
    content: '';
    display: block;
    width: 6px;
    height: 12px;
    border: solid grey;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    position: absolute;
    top: 3px;
    left: 9px;
    margin: 4px;
  }

  @media (max-width: 500px) {
    font-size: 0.8em;
  }
`;

const CheckboxParagraph = css`
  margin-left: 30px;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  font-style: italic;
  span {
    color: #0a79cd;
    text-decoration: underline;
  }
`;

// Change Address container on final-frame step
const ChangeAddress = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* max-width: 450px; */
  margin-top: 5px;

  p {
    margin-bottom: 0;
  }
  button {
    color: #1c406a;
    text-decoration: underline;
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    text-transform: capitalize;
  }
`;

const AddressSubtext = css`
  width: 100%;
  max-width: 450px;
  margin: auto;
`;
const ThirdPartyContainer = css`
  color: #8b939d;
  width: calc(100% - 20px);
  max-width: 650px;
  margin: auto;
  text-align: left;
`;
const LenderDropdown = css`
  background: #fff;
  margin-bottom: 10px;
  padding: 0 10px;
  position: absolute;
  width: 100%;

  p {
    margin-bottom: 0;
    border-bottom: 1px solid #ddd;
    padding: 5px;
    &:hover,
    &:focus {
      background: ${DEFAULT_COLORS.link};
      color: #fff;
      cursor: pointer;
    }
  }
`;

const FlexContainer = css`
  max-width: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`;
const PersistentMessage = css`
  font-size: 12px;
  width: 75%;
  margin: 10px auto 0;
  text-align: center;
  color: #777;
  .main-app-page-0 & {
    color: #fff;
  }
  .main-app-page-61 & {
    display: none;
  }
  .ast.surveyContainerFF & {
    display: none;
  }
  @media (max-width: 801px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;

export default {
  PersistentMessage,
  HeaderContainer,
  HeaderImage,
  AppWrapper,
  SurveyBackground,
  SurveyContainer,
  QuestionNumber,
  FormContainer,
  GlobalSealImg,
  ChoiceContainer,
  InputContainer,
  inputStyle,
  InputField,
  StyledTextArea,
  AddressContainer,
  StyledSelect,
  StyledZip,
  FinalInputContainer,
  HiddenContainer,
  QuestionTitle,
  InputTitle,
  Subtitle,
  TipContainer,
  QuestionContainer,
  QuestionBodyContainer,
  StyledSlider,
  StyledThumb,
  DisplayNumber,
  NumberContainer,
  SliderContainer,
  FillContainer,
  ProgressContainer,
  NumberStatus,
  StatusBar,
  FooterNumberStatus,
  SubmitButton,
  BackButton,
  ButtonContainer,
  BackButtonSpacer,
  FooterContainer,
  DisclosureContainer,
  ModalButtonContainer,
  ModalButton,
  ModalBackground,
  ModalContainer,
  ExitButton,
  ModalInformation,
  BoldText,
  Text,
  FooterText,
  ImageList,
  HeadlineContainer,
  HeadlineHeader,
  SubHeader,
  SmallText,
  ErrorText,
  FinalDisclosureContainer,
  SpinnerContainer,
  Spinner,
  ThirdPartyContainer,
  LoadingContainer,
  SpinnerMainContainer,
  CongratsHolder,
  CheckboxWrapper,
  CheckboxContainer,
  CheckboxLabel,
  CheckboxParagraph,
  ChangeAddress,
  AddressSubtext,
  LenderDropdown,
  FlexContainer,
  ProgressBarContainer,
};
